
export interface CookieItem {
    name: string;
    description: string;
    accepted: boolean;
    alwaysAccept?: boolean;
}

export enum DefaultCookieNames {
    TECHNICAL = "TECHNICAL",
    GOOGLE_ANALYTICS = "GOOGLE_ANALYTICS",
    BILLIGER_DE = "BILLIGER_DE",
    PAYPAL = "PAYPAL",
    CLARITY = "CLARITY"
}

export const defaultCookieList: CookieItem[] = [
    {
        name: DefaultCookieNames.TECHNICAL, description: "COOKIE_CONSENT.DESCRIPTION.TECHNICAL",
        accepted: true, alwaysAccept: true
    },
    {name: DefaultCookieNames.GOOGLE_ANALYTICS, description: "COOKIE_CONSENT.DESCRIPTION.GA", accepted: true},
    {name: DefaultCookieNames.BILLIGER_DE, description: "COOKIE_CONSENT.DESCRIPTION.BILLIGER_DE", accepted: true},
    {name: DefaultCookieNames.PAYPAL, description: "COOKIE_CONSENT.DESCRIPTION.PAYPAL", accepted: true},
    {name: DefaultCookieNames.CLARITY, description: "COOKIE_CONSENT.DESCRIPTION.CLARITY", accepted: true},
];