import React, {useEffect, useState} from "react";
import {withCookies} from "react-cookie";
import {useTranslation, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CookieConsentReducerActionType} from "../../../redux-store/reducers/cookieConsentReducer";
import {selectorAllowedCookies} from "../../../redux-store/selectors/cookieSelectors";
import CookieChangeModal from "./CookieChangeModal/CookieChangeModal";
import {defaultCookieList} from "./DefaultCookieList";
import {AnalyticsApi} from "../../../analytics/AnalyticsApi";


function CookieConsent({cookies}) {
    const [hideBanner, setHideBanner] = useState(true);
    const consentCookieName = "wcs-cookie-consent";
    const [t] = useTranslation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const storedAllowedCookies = useSelector(selectorAllowedCookies);

    useEffect(() => {
         // AnalyticsApi.setDefaultGAConsent();
        setHideBanner(cookies.get(consentCookieName));
        // if(cookies.get(consentCookieName)){
        //     const allowedCookies: string[] = [];
        //     defaultCookieList.map(cookie => {
        //         const cookieName = cookie.name;
        //         if(localStorage.getItem(cookieName) == "true"){
        //             allowedCookies.push(cookieName);
        //         }
        //     });
        //     // AnalyticsApi.updateGAConsent(allowedCookies.includes("GOOGLE_ANALYTICS"));
        //     dispatchCookies(allowedCookies);
        // }
    }, []);

    useEffect(() => {
        setHideBanner(cookies.get(consentCookieName));

        //Deactivates Clarity
        // if(window && !storedAllowedCookies.includes('CLARITY')) {
        //     if (window.clarity && typeof window.clarity === 'function') {
        //         window.clarity('stop');
        //         window.clarity('consent', false);
        //     }
        // }


    }, [storedAllowedCookies]);

    function acceptAllCookies() {
        cookies.set(consentCookieName, "true", {path: "/"});
        const allowedCookies: string[] = [];
        defaultCookieList.map(cookie => {
            allowedCookies.push(cookie.name)
            localStorage.setItem(cookie.name, "true");
        });
        dispatchCookies(allowedCookies);
    }

    function dispatchCookies(allowedCookies: string[]) {
        dispatch({type: CookieConsentReducerActionType.SET, payload: {allowedCookies: allowedCookies}});
        setHideBanner(true);
    }

    return !hideBanner ? (
        <>
            <div className="cookie-wrapper">
                <div className="cookie-banner alert alert-success m-3">
                    <div className="my-2">
                        {t("COOKIE_CONSENT.TEXT_1")}
                        <Link to="/datenschutz">{t("COOKIE_CONSENT.TEXT_2")}</Link>
                        {t("COOKIE_CONSENT.TEXT_3")}
                    </div>
                    <div className={"w-100 d-flex justify-content-end px-1"}>
                        <button className="btn btn-success me-3"
                                onClick={() => acceptAllCookies()}>
                            {t("COOKIE_CONSENT.ACCEPT")}
                        </button>
                        <button className="btn btn-outline-secondary d-inline-block"
                                onClick={() => setModalOpen(true)}>
                            {t("COOKIE_CONSENT.CUSTOMIZE")}
                        </button>
                    </div>
                </div>
            </div>
            <CookieChangeModal modalOpen={modalOpen} showModalHandler={setModalOpen}></CookieChangeModal>
        </>
    ) : null;
}

export default withTranslation()(withCookies(CookieConsent));