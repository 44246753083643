import * as React from "react";
import { RouteProps } from "react-router";
import Checkout from "./pages/Checkout/Checkout";
import OrderConfirmation from "./pages/Checkout/OrderConfirmation/OrderConfirmation";
import PayPalCancel from "./pages/Checkout/Payment/PayPalCancel";
import PayPalComplete from "./pages/Checkout/Payment/PayPalComplete";
import FavoriteView from "./pages/FavoriteView/FavoriteView";
import Home from "./pages/Home/Home";
import Imprint from "./pages/legals/Imprint/Imprint";
import PrivacyPolicy from "./pages/legals/PrivacyPolicy/PrivacyPolicy";
import Revocation from "./pages/legals/Revocation/Revocation";
import TOS from "./pages/legals/TOS/TOS";
import Login from "./pages/Login/Login";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import ActivateAccount from "./pages/Registration/ActivateAccount/ActivateAccount";
import Registration from "./pages/Registration/Registration";
import RimDetails from "./pages/rims/RimDetails/RimDetails";
import RimSearch from "./pages/rims/RimSearch/RimSearch";
import SeoMarketingPage from "./pages/SeoMarketingPage/SeoMarketingPage";
import Contact from "./pages/service-infos/Contact/Contact";
import DeliveryInformation from "./pages/service-infos/DeliveryInformation/DeliveryInformation";
import FAQ from "./pages/service-infos/FAQ/FAQ";
import Gallery from "./pages/service-infos/Gallery/Gallery";
import Press from "./pages/service-infos/Press/Press";
import RDKS from "./pages/service-infos/RDKS/RDKS";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import TyreDetails from "./pages/tyres/TyreDetails/TyreDetails";
import TyreSearch from "./pages/tyres/TyreSearch/TyreSearch";
import OrderDetails from "./pages/UserSettings/OrderHistory/OrderDetails/OrderDetails";
import Orders from "./pages/UserSettings/OrderHistory/OrderHistory";
import Password from "./pages/UserSettings/Password/Password";
import UserData from "./pages/UserSettings/UserData/UserData";
import UserSettings from "./pages/UserSettings/UserSettings";

interface CustomRouteProps extends Omit<RouteProps, "component"> {
    component?: React.ComponentType<any> | { serverFetch?: Function };
}

export type serverFetch = (params: any, url?: string) => Promise<any>;

export const seoMarketingPaths = [
    "/winterkomplettraeder/",
    "/winterraeder/",
    "/winterfelgen/",
    "/sommerkomplettraeder/",
    "/sommerraeder/",
    "/sommerfelgen/",
    "/komplettraeder/",
    "/komplettradkonfigurator/",
    "/audi-komplettraeder.html",
    "/bmw-komplettraeder.html",
    "/fiat-komplettraeder.html",
    "/ford-komplettraeder.html",
    "/hyundai-komplettraeder.html",
    "/mazda-komplettraeder.html",
    "/mercedes-komplettraeder.html",
    "/nissan-komplettraeder.html",
    "/opel-komplettraeder.html",
    "/renault-komplettraeder.html",
    "/seat-komplettraeder.html",
    "/skoda-komplettraeder.html",
    "/tesla-komplettraeder.html",
    "/toyota-komplettraeder.html",
    "/volvo-komplettraeder.html",
    "/vw-komplettraeder.html",
    "/felgenkonfigurator.html"
];

export const routes: Array<{
        preventScrollTop?: boolean,
        path: string,
        component: any,
        strict?: boolean;
    }> = [
        {
            path: "/login",
            component: Login
        }, {
            path: "/activate-account/:userId/:token",
            component: ActivateAccount
        }, {
            path: "/register",
            component: Registration
        }, {
            path: "/password-reset/:token?",
            component: PasswordReset
        }, {
            path: "/einstellungen",
            component: UserSettings
        }, {
            path: "/einstellungen/passwort",
            component: Password
        }, {
            path: "/einstellungen/bestellungen",
            component: Orders
        }, {
            path: "/einstellungen/bestellungen/:id",
            component: OrderDetails
        }, {
            path: "/einstellungen/nutzerdaten",
            component: UserData
        }, {
            path: "/reifen/:matchcodeFront/:matchcodeRear/:carId/:rimFrontId/:rimRearId/",
            component: TyreSearch
        }, {
            path: "/reifen",
            component: TyreSearch
        }, {
            path: "/reifen/details/:seoId/",
            component: TyreDetails
        }, {
            path: "/",
            component: Home
        }, {
            path: "/favoriten",
            component: FavoriteView
        }, {
            path: "/felgen/:brand?",
            component: RimSearch
        }, {
            path: "/felgenkonfigurator/:carId?",
            component: RimSearch
        }, {
            path: "/felgen/details/:seoRimId/:seoCarId?",
            component: RimDetails,
            preventScrollTop: true
        }, {
            path: "/warenkorb",
            component: ShoppingCart
        }, {
            path: "/checkout",
            component: Checkout
        }, {
            path: "/checkout/paypal/complete",
            component: PayPalComplete
        }, {
            path: "/checkout/paypal/cancel",
            component: PayPalCancel
        }, {
            path: "/order-confirmation/:status",
            component: OrderConfirmation
        }, {
            path: "/nutzungsbedingungen",
            component: TOS
        }, {
            path: "/impressum",
            component: Imprint
        }, {
            path: "/datenschutz",
            component: PrivacyPolicy
        }, {
            path: "/widerruf",
            component: Revocation
        }, {
            path: "/versand",
            component: DeliveryInformation
        }, {
            path: "/faq",
            component: FAQ
        }, {
            path: "/rdks",
            component: RDKS
        }, {
            path: "/kontakt",
            component: Contact
        }, {
            path: "/galerie",
            component: Gallery
        }, {
            path: "/presse",
            component: Press
        }
    ].concat(seoMarketingPaths.map(path => {
            return {
                path: path,
                component: SeoMarketingPage
            };
        }
    ))
;
