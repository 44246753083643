import { useLayoutEffect, useState } from "react";

export enum Orientation {
    PORTRAIT = 'portrait-primary',
    LANDSCAPE = 'landscape-primary',
    UNKOWN = "UNKOWN"

}
export interface ScreenOrientation {
    angle: number;
    type: Orientation;
}
export function useOrientation(): ScreenOrientation {
    const [orientation, setOrientation] = useState({
        angle: 0,
        type: Orientation.LANDSCAPE
    });

    useLayoutEffect(() => {
        const handleChange = () => {
            const { angle, type } = window?.screen.orientation;

            setOrientation({
                angle: angle,
                type: type as Orientation
            });
        };

        const handle_orientationchange = () => {
            setOrientation({
                type: window?.orientation == 90 ? Orientation.LANDSCAPE : Orientation.PORTRAIT,
                angle: window?.orientation,
            });
        };

        if (window.screen?.orientation) {
            handleChange();
            window.screen.orientation.addEventListener("change", handleChange);
        } else {
            handle_orientationchange()
            window.addEventListener("orientationchange", handle_orientationchange);
        }

        return () => {
            if (window.screen?.orientation) {
                window.screen.orientation.removeEventListener("change", handleChange);
            } else {
                window.removeEventListener(
                    "orientationchange",
                    handle_orientationchange
                );
            }
        };
    }, []);

    return orientation;
}