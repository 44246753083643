import { TFunction } from "i18next";
import React from "react";
import { NumericFormat } from "react-number-format";
import { ReactSelectOption } from "../../models/shared/ReactSelectOption";

export class InputHelper {
    static filterLabel(
        value: string | number,
        key: string,
        translate?: TFunction,
        translateValue?: boolean,
        translatePrefix?: string,
    ): string | React.JSX.Element {
        if (key === "MIN_QUANTITY" && translate) {
            switch (value) {
                case "12":
                    return translate("FILTER.AVAILABLE");
                case "4":
                    return translate("FILTER.LESS_AVAILABLE");
                case "0":
                    return translate("FILTER.NONE_AVAILABLE");
                default:
                    return value + "";
            }
        } else if (["NOISE_EMISSION", "PRICE", "RETAILPRICE"].includes(key)) {
            return (
                <NumericFormat
                    value={value}
                    displayType={"text"}
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    suffix={key === "NOISE_EMISSION" ? "dB" : "€"}
                />
            );
        }
        if (value === "TRUE" && key && translate) {
            return translate(`FILTER.${key}`);
        }
        if (translate && translateValue) {
            return translate(`${translatePrefix ?? "INPUT.TRANSLATE_VALUES"}.${value}`);
        }
        return value + "";
    }

    static formatToReactSelectSingleOption(option: string, addClearingOption = false): ReactSelectOption {
        if (option || addClearingOption) {
            return {
                value: option,
                label: option || "-",
            };
        }
    }
    static formatToReactSelectMultiOptions(options: string[], addClearingOption = false): ReactSelectOption[] {
        return options
            ?.filter((o) => (addClearingOption ? true : o))
            .map((o) => this.formatToReactSelectSingleOption(o, addClearingOption));
    }
    static formatToReactSelectOptions(
        options: string | string[],
        addClearingOption = false,
    ): ReactSelectOption | ReactSelectOption[] {
        if (Array.isArray(options)) {
            return this.formatToReactSelectMultiOptions(options, addClearingOption);
        } else {
            return this.formatToReactSelectSingleOption(options, addClearingOption);
        }
    }

    static additionalInformationAvailable = {
        WINTERPROOF: true,
        ABE: true,
    };
}
