import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Footer() {
    const [t] = useTranslation();

    return (
        <div id="Footer" className="footer">
            <div className="footer_top container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-2 mb-4 mb-xl-0">
                        <div className="phone-number">
                            {/*TODO: WCS-716
                            <div className="text-highlight"><a href="tel:+498002583354">0800-Alufelgen</a></div>
                            <div><a href="tel:+498002583354">0800 258 33 54</a></div>*/}
                            <div className="text-highlight"><a href="tel:+494264776930">04264 77693 0</a></div>
                            <div>&nbsp;</div>
                        </div>
                        <div className="opening-times">
                            <div className="week mt-4">
                                <div className="day">{t('FOOTER.OPENING_TIMES.WEEK_DAYS')}</div>
                                <div>{t('FOOTER.OPENING_TIMES.WEEK_DAYS_TIMES')}</div>
                            </div>
                        </div>
                        <div className="info-text">
                            {t('FOOTER.INFO_TEXT')}
                            <b className="no-wrap"><a href="tel:+494264776930"> (+49) 04264 77693 0</a></b>.
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <h3>{t('FOOTER.RIMS_AND_TYRES.TITLE')}</h3>
                        <ul>
                            <li><Link to="/">{t('FOOTER.RIMS_AND_TYRES.CONFIGURATOR')}</Link></li>
                            <li><Link to="/felgen">{t('FOOTER.RIMS_AND_TYRES.RIMS_OVERVIEW')}</Link></li>
                            <li><Link to="/reifen">{t('FOOTER.RIMS_AND_TYRES.TYRE_SEARCH_PER_SIZE')}</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <h3>{t('FOOTER.SERVICE_AND_INFOS.TITLE')}</h3>
                        <ul>
                            <li><Link to="/faq" title={t('FOOTER.SERVICE_AND_INFOS.FAQ')}>{t('FOOTER.SERVICE_AND_INFOS.FAQ')}</Link></li>
                            <li><Link to="/rdks" title={t('FOOTER.SERVICE_AND_INFOS.TPMS')}>{t('FOOTER.SERVICE_AND_INFOS.TPMS')}</Link></li>
                            <li><Link to="/kontakt" title={t('FOOTER.SERVICE_AND_INFOS.CONTACT')}>{t('FOOTER.SERVICE_AND_INFOS.CONTACT')}</Link></li>
                            <li><Link to="/versand" title={t('FOOTER.SERVICE_AND_INFOS.SHIPPING')}>{t('FOOTER.SERVICE_AND_INFOS.SHIPPING')}</Link></li>
                            <li><Link to="/versand/#Zahlung" title={t('FOOTER.SERVICE_AND_INFOS.PAYMENT')}>{t('FOOTER.SERVICE_AND_INFOS.PAYMENT')}</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <h3>{t('FOOTER.LEGALS.TITLE')}</h3>
                        <ul>
                            <li><a href="/AGB.pdf" title={t('FOOTER.LEGALS.AGB')} target="_blank">{t('FOOTER.LEGALS.AGB')}</a></li>
                            <li><Link to="/impressum" title={t('FOOTER.LEGALS.IMPRINT')}>{t('FOOTER.LEGALS.IMPRINT')}</Link></li>
                            <li><Link to="/datenschutz" title={t('FOOTER.LEGALS.DATA_PROTECTION')}>{t('FOOTER.LEGALS.DATA_PROTECTION')}</Link></li>
                            <li><Link to="/nutzungsbedingungen" title={t('FOOTER.LEGALS.TOS')}>{t('FOOTER.LEGALS.TOS')}</Link></li>
                            <li><Link to="/widerruf" title={t('FOOTER.LEGALS.REVOCATION')}>{t('FOOTER.LEGALS.REVOCATION')}</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                        <h3>{t('FOOTER.WHEELSCOMPANY.TITLE')}</h3>
                        <ul>
                            <li><Link to="/galerie" title={t('FOOTER.WHEELSCOMPANY.GALLERY')}>{t('FOOTER.WHEELSCOMPANY.GALLERY')}</Link></li>
                            <li><Link to="/presse" title={t('FOOTER.WHEELSCOMPANY.PRESS')}>{t('FOOTER.WHEELSCOMPANY.PRESS')}</Link></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-2 certificates">
                        <img src="/home/tuev_cert.png" className="img-fluid" alt="tuev-cert"/>
                        <img src="/home/s1_idealo-partner.png" className="img-fluid ms-4" alt="idealo"/>
                    </div>
                </div>
            </div>
            <div className="footer_bottom container-fluid">
                <Link to="/" className="logo-footer"/>
                <div className="text-box">
                    Wheelscompany GmbH | a webshop developed by <a href="https://n4.de" target="_blank">N4</a>
                </div>
                <div className="social-links">
                    <a href="https://www.facebook.com/Felgen.Hamburg/?locale=de_DE" target="_blank" className="footer-icon fab fa-facebook-f"/>
                    <a href="https://www.instagram.com/wheelscompany/" target="_blank" className="footer-icon fab fa-instagram"/>
                    {/*<i className="footer-icon fab fa-twitter"/>
                    <i className="footer-icon fab fa-whatsapp"/>
                    */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loggedIn: state.loggedIn,
    firmData: state.firmData,
    domain: state.domain
});
export default connect(mapStateToProps)(Footer);
